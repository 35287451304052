import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AUTH_KEYS } from 'src/app/core/enums';
import { AESEncryptDecryptServiceService } from 'src/app/shared/services/aesencrypt-decrypt-service.service';
import { MainService } from '../public-api';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  profile: any

  constructor(private cookieService: CookieService, private aesService: AESEncryptDecryptServiceService, private mainService: MainService) {
    this.mainService.profileDetail$.subscribe((res: any) => {
      if (res) {
        this.setAccountType = res?.assignedRole?.name || ''
      }
    })
  }

  get getUserPermissions() { return this.cookieService.get(AUTH_KEYS.USER_PERMISSION_TYPE || []) }

  get getPermissions() { return (this.cookieService.get(AUTH_KEYS.USER_PERMISSION) || []) }

  get accountType() { return (this.cookieService.get(AUTH_KEYS.USER_ACCOUNT_TYPE) || '') }

  hasPermissions(permission: string): boolean {
    if (this.isAdmin()) { return true }
    if (this.aesService.decryptData(this.getUserPermissions).includes(permission)) { return true }
    // return this.hasSubPermissions(permission)
    return false
  }

  isAdmin(): boolean {
    if (this.accountType === 'admin') { return true }
    return false
  }

  hasSubPermissions(permission: string): boolean {
    let returnValue: boolean = false;
    (this.aesService.decryptData(this.getPermissions)).forEach((ele: any) => {
      if (ele.name === permission) {
        returnValue = true
      }
    })
    return returnValue
  }

  get getAuthToken(): string {
    return this.cookieService.get('accessToken') || '';
  }

  set setAuthToken(token: any) {
    this.cookieService.set('accessToken', token, { path: "/" });
  }

  set setAccountType(role: string) {
    this.cookieService.set('rsa_account_type', role, { path: "/" });
  }

  set setPermissions(data: any) {
    this.cookieService.set('permissions', JSON.stringify(this.aesService.encryptData(data)), { path: "/" });
  }

  get getUserDetail(): string {
    return this.cookieService.get('user') || '';
  }

  set setUserDetail(token: any) {
    this.cookieService.set('user', token, { path: "/" });
  }
  get getLoginCreds(): any {
    return this.cookieService.get('userLogins') ? (this.aesService.decryptData(JSON.parse(this.cookieService.get('userLogins')))) : '';
  }

  set setLoginCreds(creds: any) {
    this.cookieService.set('userLogins', JSON.stringify(this.aesService.encryptData(creds)), { path: "/" });
  }

  clearLogins() {
    this.cookieService.delete('userLogins', '/');
  }

  clearCookie() {
    this.cookieService.delete('user', '/');
    this.cookieService.delete('accessToken', '/');
    this.cookieService.delete('permissions', '/');
  }

}
