<div class="sidebar-inner">
  <ul class="list-none">
    <li class="nav-item" *ngIf="commonService.hasPermissions('dashboard:read')" (click)="setCurrentOpenItem('')">
      <a routerLink="/panel/dashboard" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
        class="nav-link"><span class="sidebar__icon svg-black dashboard-icon">
          <img src="assets/images/svg/dashboard.svg" class="width-20 text-start me-0">
        </span>
        Dashboard
      </a>
    </li>
    <!---->
    <li class="nav-item">
      <a routerLinkActive="active" (click)="showSidebarUsersDrop()" class="nav-link nav-link text-truncate"
        data-target="#submenu1"
        [ngClass]="(currentOpenItem === 'users' && !showManageUsersItems) || checkActiverRoute('panel/manage-user/users') || checkActiverRoute('panel/manage-user/manage-drivers') || checkActiverRoute('/panel/manage-roles') ? 'active' : ''">
        <span class="sidebar__icon text-start svg-black">
          <img src="assets/images/svg/manage-users.svg" alt="icon" class="width-20"></span>Users<i
          class="fas fa-chevron-down ms-3 position-absolute right-15 top-15" *ngIf="!showManageUsersItems"></i>
        <i class="fas fa-chevron-up ms-3 position-absolute right-15 top-15" *ngIf="showManageUsersItems"></i>
      </a>
    </li>
    <div class="ps-2" id="submenu1" *ngIf="showManageUsersItems" aria-expanded="false">
      <ul class="flex-column pl-2 ms-2 nav">
        <li *ngIf="commonService.hasPermissions('users:read')">
          <a routerLink="/panel/manage-user/users" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('users')">
            <span class="sidebar__icon">
              <img src="assets/images/svg/manage-users.svg" class="width-20 text-start me-0">
            </span> Manage Users
          </a>
        </li>
        <li class="nav-item" *ngIf="commonService.hasPermissions('drivers:read')">
          <a routerLink="/panel/manage-user/manage-drivers" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('users')">
            <span><img src="assets/images/driver.svg" alt="icon" style="max-height: 20px;"></span>
            Manage Drivers
          </a>
        </li>
        <li class="nav-item" *ngIf="commonService.hasPermissions('roles:read')">
          <a routerLink="/panel/manage-user/manage-roles" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('users')">
            <span class="sidebar__icon svg-black text-start sidebar__roles">
              <img src="assets/images/svg/roles-management.svg" class="width-20 text-start me-0">
            </span>Roles Management
          </a>
        </li>
      </ul>
    </div>


    <li class="nav-item">
      <a routerLinkActive="active" (click)="showSidebarRideDrop()" class="nav-link nav-link  text-truncate"
        data-target="#submenuRide"
        [ngClass]="(currentOpenItem === 'manage-ride' && !showManageRide) || checkActiverRoute('panel/manage-ride/manage-offer-ride') || checkActiverRoute('panel/manage-ride/manage-bookings') || checkActiverRoute('panel/manage-ride/manage-refund-process') ? 'active' : ''">
        <span class="sidebar__icon text-start svg-black">
          <img src="assets/images/car.png" alt="icon" class="width-20"></span>Manage Ride<i
          class="fas fa-chevron-down ms-3 position-absolute right-15 top-15" *ngIf="!showManageRide"></i>
        <i class="fas fa-chevron-up ms-3 position-absolute right-15 top-15" *ngIf="showManageRide"></i>
      </a>
    </li>
    <div class="ps-2" id="submenuRide" *ngIf="showManageRide" aria-expanded="false">
      <ul class="flex-column pl-2 ms-2 nav">
        <li class="nav-item" *ngIf="commonService.hasPermissions('offerRides:read')"
          (click)="setCurrentOpenItem('manage-ride')">
          <a routerLink="/panel/manage-ride/manage-offer-ride" routerLinkActive="active" class="nav-link text-black">
            <span class="sidebar__icon text-start"><img src="assets/images/car.png" alt="icon"
                class="width-20 text-start me-0"></span>Manage Offer Ride
          </a>
        </li>
        <li class="nav-item" *ngIf="commonService.hasPermissions('bookings:read')"
          (click)="setCurrentOpenItem('manage-ride')">
          <a routerLink="/panel/manage-ride/manage-bookings" routerLinkActive="active" class="nav-link text-black">
            <span class="sidebar__icon text-start"><i class="far fa-calendar-alt"></i></span>Manage Bookings
          </a>
        </li>
        <li>
          <a routerLink="/panel/manage-ride/manage-refund-process" *ngIf="commonService.hasPermissions('refund:read')"
            (click)="setCurrentOpenItem('manage-ride')" routerLinkActive="active" class="nav-link text-black">
            <span class="sidebar__icon position-relative left-2">
              <img src="assets/images/svg/refund-process.svg" alt="icon" class="width-20 text-start me-0">
            </span> Refund Process</a>
        </li>
      </ul>
    </div>

    <li>
      <a routerLink="/panel/manage-spam-report" *ngIf="commonService.hasPermissions('reports:read')"
        routerLinkActive="active" class="nav-link text-black"><span class="sidebar__icon text-start text-black"><img
            src="/assets/images/customer-feedback.png" alt="icon" class="width-20"></span>Complaints</a>
    </li>

    <li class="nav-item" *ngIf="commonService.hasPermissions('travelPreferences:read')"
      (click)="setCurrentOpenItem('')">
      <a routerLink="/panel/manage-category" routerLinkActive="active" class="nav-link">
        <span class="sidebar__icon text-start"><i class="fa-solid fa-list"></i></span>Travel Preferences
      </a>
    </li>
    <li><a routerLink="/panel/manage-coupons" *ngIf="commonService.hasPermissions('coupons:read')"
        (click)="setCurrentOpenItem('')" routerLinkActive="active" class="nav-link text-black">
        <span class="sidebar__icon">
          <img src="assets/images/coupen-icon.svg" alt="" class="width-20"></span>
        Manage Coupons</a></li>

    <li class="nav-item">
      <a routerLink="/panel/manage-vehicle" routerLinkActive="active" class="nav-link" (click)="setCurrentOpenItem('')">
        <span class="sidebar__icon text-start"><img src="assets/images/car.png" alt="icon"
            class="width-20 text-start me-0"></span>Manage Vehicle
      </a>
    </li>
    <li class="nav-item">
      <a routerLinkActive="active" (click)="showSidebarPriceDrop()" class="nav-link nav-link  text-truncate"
        data-target="#submenu1"
        [ngClass]="(currentOpenItem === 'manage-price' && !showPriceItems) || checkActiverRoute('panel/manage-price/price-catalogue') || checkActiverRoute('panel/manage-price/manage-commision') || checkActiverRoute('panel/manage-price/financial-year') ? 'active' : ''">
        <span class="sidebar__icon text-start svg-black">
          <img src="assets/images/price-catalogue.svg" alt="icon" class="width-20"></span>Manage Price <i
          class="fas fa-chevron-down ms-3 position-absolute right-15 top-15" *ngIf="!showPriceItems"></i>
        <i class="fas fa-chevron-up ms-3 position-absolute right-15 top-15" *ngIf="showPriceItems"></i>
      </a>
    </li>
    <div class="ps-2" id="submenu1" *ngIf="showPriceItems" aria-expanded="false">
      <ul class="flex-column pl-2 ms-2 nav">
        <li>
          <a routerLink="/panel/manage-price/price-catalogue" *ngIf="commonService.hasPermissions('price:read')"
            (click)="setCurrentOpenItem('manage-price')" routerLinkActive="active" class="nav-link text-black"><span
              class="sidebar__icon">
              <img src="assets/images/price-catalogue.svg" alt="" class="width-20"></span> Price Catalogue</a>
        </li>
        <li>
          <a routerLink="/panel/manage-price/manage-commision" (click)="setCurrentOpenItem('manage-price')"
            routerLinkActive="active" class="nav-link text-black"><span class="sidebar__icon">
              <img src="assets/images/price-catalogue.svg" alt="" class="width-20"></span> Manage Commission</a>
        </li>
        <li>
          <a routerLink="/panel/manage-price/financial-year" (click)="setCurrentOpenItem('financial-year')"
            routerLinkActive="active" class="nav-link text-black"><span class="sidebar__icon">
              <img src="assets/images/price-catalogue.svg" alt="" class="width-20"></span> Financial Year</a>
        </li>
        <!-- <li>
          <a routerLink="/panel/manage-price/set-invoice-number" (click)="setCurrentOpenItem('invoce-number')"
            routerLinkActive="active" class="nav-link text-black"><span class="sidebar__icon">
              <img src="assets/images/price-catalogue.svg" alt="" class="width-20"></span> Set Invoice Number</a>
        </li> -->
      </ul>
    </div>

    <!-- <li><a routerLink="/panel/manage-spam-report" *ngIf="commonService.hasPermissions('reports:read')"
        (click)="setCurrentOpenItem('')" routerLinkActive="active" class="nav-link text-black"><span
          class="sidebar__icon text-start text-black"><img src="/assets/images/envelope.svg" alt="icon"
            class="width-20"></span>Spam Reports</a></li> -->

    <li class="nav-item">
      <a routerLinkActive="active" (click)="showSidebarReportDrop()" class="nav-link nav-link  text-truncate"
        data-target="#submenu1"
        [ngClass]="(currentOpenItem === 'manage-reports' && !showReportItems) || checkActiverRoute('panel/manage-earning-and-payouts-report') || checkActiverRoute('panel/manage-pending-amount-report') || checkActiverRoute('panel/manage-commission-report') || checkActiverRoute('panel/manage-refund-report') ? 'active' : ''">
        <span class="sidebar__icon text-start svg-black">
          <img src="/assets/images/report-icon.svg" alt="icon" class="width-20"></span>Reports <i
          class="fas fa-chevron-down ms-3 position-absolute right-15 top-15" *ngIf="!showReportItems"></i>
        <i class="fas fa-chevron-up ms-3 position-absolute right-15 top-15" *ngIf="showReportItems"></i>
      </a>
    </li>
    <div class="ps-2" id="submenu1" *ngIf="showReportItems" aria-expanded="false">
      <ul class="flex-column pl-2 ms-2 nav">
        <li>
          <a routerLink="/panel/manage-earning-and-payouts-report/earnings-and-payments" routerLinkActive="active"
            (click)="setCurrentOpenItem('manage-reports')" class="nav-link text-black"><span
              class="sidebar__icon text-start text-black">
              <img src="/assets/images/earn-icon.svg" alt="icon" class="width-20"></span>Earning & Payout</a>
        </li>
        <li>
          <a routerLink="/panel/manage-pending-amount-report/pendingAmounts" routerLinkActive="active"
            (click)="setCurrentOpenItem('manage-reports')" class="nav-link text-black">
            <span class="sidebar__icon text-start"><img src="assets/images/acc-setting.svg" alt="icon"
                class="width-20"></span>Pending amount</a>
        </li>
        <li>
          <a routerLink="/panel/manage-pending-amount-report/wallet-history" routerLinkActive="active"
            (click)="setCurrentOpenItem('manage-reports')" class="nav-link text-black">
            <span class="sidebar__icon text-start"><img src="assets/images/wallet.png" alt="icon"
                class="width-20"></span>Wallet history</a>
        </li>
        <li>
          <a routerLink="/panel/manage-commission-report/commission" routerLinkActive="active"
            (click)="setCurrentOpenItem('manage-reports')" class="nav-link text-black"><span
              class="sidebar__icon text-start text-black"><img src="assets/images/commission-icon.png" alt="icon"
                class="width-20"></span>Commission report</a>
        </li>
        <li>
          <a routerLink="/panel/manage-refund-report/refund" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('manage-reports')"><span class="sidebar__icon text-start text-black"><img
                src="assets/images/refund.png" alt="icon" class="width-20"></span>Refund report</a>
        </li>
      </ul>
    </div>
    <li class="nav-item">
      <a routerLink="/panel/notification" routerLinkActive="active" class="nav-link" (click)="setCurrentOpenItem('')">
        <span class="sidebar__icon text-start"><img src="assets/images/notification.png" alt="icon"
            class="width-20 text-start me-0"></span>Manage Notification
      </a>
    </li>

    <li class="nav-item">
      <a routerLinkActive="active" (click)="showInforDrop()" class="nav-link nav-link  text-truncate"
        [ngClass]="(currentOpenItem === 'manage-info' && !showInfoItems) || checkActiverRoute('panel/informative') ? 'active' : ''"
        data-target="#submenu1">
        <span class="sidebar__icon text-start svg-black">
          <img src="assets/images/info.svg" alt="icon" class="width-20"></span>Informative <i
          class="fas fa-chevron-down ms-3 position-absolute right-15 top-15" *ngIf="!showInfoItems"></i>
        <i class="fas fa-chevron-up ms-3 position-absolute right-15 top-15" *ngIf="showInfoItems"></i>
      </a>
    </li>
    <div class="ps-2" id="submenu1" *ngIf="showInfoItems" aria-expanded="false">
      <ul class="flex-column pl-2 ms-2 nav">
        <li class="nav-item" *ngIf="commonService.hasPermissions('privaryPolicy:read')">
          <a routerLink="/panel/informative/privacy-policy" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('manage-info')">
            <span class="sidebar__icon text-start svg-black">
              <img src="assets/images/svg/privacy-policy.svg" alt="icon" class="width-20">
            </span>Privacy Policy
          </a>
        </li>

        <li class="nav-item" *ngIf="commonService.hasPermissions('termsConditions:read')">
          <a routerLink="/panel/informative/terms-conditions" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('manage-info')">
            <span class="sidebar__icon text-start svg-black">
              <img src="assets/images/svg/terms-conditions.svg" alt="icon" class="width-20">
            </span>Terms & Conditions
          </a>
        </li>
        <li class="nav-item" *ngIf="commonService.hasPermissions('faq:read')">
          <a routerLink="/panel/informative/faq" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('manage-info')">
            <span class="sidebar__icon text-start svg-black">
              <i class="fa-regular fa-circle-question fs-6"></i> </span>FAQ
          </a>
        </li>
        <li class="nav-item" *ngIf="commonService.hasPermissions('aboutUs:read')">
          <a routerLink="/panel/informative/about" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('manage-info')">
            <span class="sidebar__icon text-start svg-black">
              <img src="assets/images/info.svg" alt="icon" class="width-18 text-start" alt="Icon"></span>About us
          </a>
        </li>
        <li class="nav-item" *ngIf="commonService.hasPermissions('support:read')">
          <a routerLink="/panel/informative/support-and-contact" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('manage-info')">
            <span class="sidebar__icon text-start svg-black">
              <i class="fa-solid fa-headset"></i> </span>Support & Contact
          </a>
        </li>
      </ul>
    </div>
    <li class="nav-item" *ngIf="commonService.hasPermissions('settings:read')">
      <a routerLinkActive="active" (click)="showSidebarDrop()" class="nav-link nav-link  text-truncate"
        data-target="#submenu1"
        [ngClass]="(currentOpenItem === 'manage-setting' && !showInfoItems) || checkActiverRoute('panel/settings') ? 'active' : ''">
        <span class="sidebar__icon text-start svg-black">
          <img src="assets/images/svg/setting.svg" alt="icon" class="width-20">
        </span>Settings <i class="fas fa-chevron-down ms-3 position-absolute right-15 top-15"
          *ngIf="!showDropItems"></i>
        <i class="fas fa-chevron-up ms-3 position-absolute right-15 top-15" *ngIf="showDropItems"></i>
      </a>
    </li>
    <div class="ps-2" id="submenu1" *ngIf="showDropItems" aria-expanded="false">
      <ul class="flex-column pl-2 ms-2 nav">
        <li>
          <a routerLink="/panel/settings/manage-email-settings" *ngIf="commonService.hasPermissions('email:read')"
            (click)="setCurrentOpenItem('panel/settings')" routerLinkActive="active" class="nav-link text-black"><span
              class="sidebar__icon text-start text-black"><img src="/assets/images/mail-settings-icon.svg" alt="icon"
                class="width-20"></span>Email Settings</a>
        </li>
        <li>
          <a routerLink="/panel/settings/account-setting" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('panel/settings')">
            <span class="sidebar__icon text-start"><img src="assets/images/acc-setting.svg" alt="icon"
                class="width-20"></span>Account Settings</a>
        </li>
        <li>
          <a routerLink="/panel/settings/theme-settings" *ngIf="commonService.hasPermissions('theme:read')"
            (click)="setCurrentOpenItem('panel/settings')" routerLinkActive="active" class="nav-link text-black"><span
              class="sidebar__icon text-start text-black"><img src="assets/images/theme.svg" alt="icon"
                class="width-20"></span>Theme Settings</a>
        </li>
        <li>
          <a routerLink="/panel/settings/manage-links" *ngIf="commonService.hasPermissions('theme:read')"
            (click)="setCurrentOpenItem('panel/settings')" routerLinkActive="active" class="nav-link text-black"><span
              class="sidebar__icon text-start text-black"><img src="assets/images/link.png" alt="icon"
                class="width-20"></span>Manage Links</a>
        </li>
        <li>
          <a routerLink="/panel/settings/manage-notification" *ngIf="commonService.hasPermissions('theme:read')"
            (click)="setCurrentOpenItem('panel/settings')" routerLinkActive="active"
            class="nav-link text-black"><span class="sidebar__icon text-start text-black"><img
                src="assets/images/notification.png" alt="icon" class="width-20"></span>Manage Notification</a>
        </li>
      </ul>
    </div>
  </ul>
</div>