import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterEvent, RouterModule, Event, Router, NavigationEnd } from '@angular/router';
import { MainService } from '../../services';
import { Subscription, filter } from 'rxjs';
import { EventBusService } from 'src/app/core/services';
import { Events } from 'src/app/core/enums';
import { EmitEvent } from 'src/app/core/helpers';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  classToggled = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  private subscription: Subscription = new Subscription();
  public profileDetail: any;

  constructor(private mainService: MainService, private eventBusService: EventBusService, private router: Router) { }

  logout() { this.eventBusService.emit(new EmitEvent(Events.SIGNOUT)); }
  ngOnInit(): void {
    this.subscription.add(this.mainService.httpgetprofile().subscribe());
    this.subscription.add(
      this.mainService.profileDetail$.subscribe((res) => { this.profileDetail = res ? res : ''; }),
    );
    this.subscription.add(
      this.router.events.pipe(filter((e: Event): e is RouterEvent => e instanceof NavigationEnd)).subscribe((e: RouterEvent) => {
        this.classToggled = false;
        this.toggleSidebar.emit(this.classToggled);
      }),
    );
  }

  public toggleField() {
    this.classToggled = !this.classToggled;
    this.toggleSidebar.emit(this.classToggled);
  }

  ngOnDestroy(): void { this.subscription.unsubscribe(); }
}
