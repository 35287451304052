<footer>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="useful-links">
            <h4 class="footer-heading">Useful Links</h4>
            <div class="footer-contant">
              <ul>
                <li>
                  <a (click)="aboutUs()">About Us</a>
                </li>
                <li>
                  <a (click)="contactUs()" >Contact Us</a>
                </li>
                <li>
                  <a (click)="faq()">FAQs</a>
                </li>
                <li>
                  <a (click)="privacyPolicy()">Privacy Policy</a>
                </li>
                <li>
                  <a (click)="termsAndCondition()">Terms &amp; Conditions</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <div class="copyright-footer ">
    <p>
      <i class="fa-regular fa-copyright" aria-hidden="true"></i> 2023-24 powered by
      <!-- <a target="_blank" href="https://www.grocersapp.com">www.grocersapp.com</a> -->
    </p>
  </div>